<template>
  <div class="home">

    <breadcrumb :items="breadcrumb" />

    <v-card flat class="card_action">
      <v-card-actions>
        <v-row justify="end">
          <v-col>
            <!-- <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Wyszukaj"
              single-line
              hide-details
            ></v-text-field> -->
            <h2>Kategorie pakietów</h2>
          </v-col>
          <v-col style="text-align: right">
            <v-btn
              color="secondary_dark"
              dark
              depressed
              tile
              to="/pakiet-kategoria-nowa"
            >
              Dodaj kategorie
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    
    <v-row>
      <v-col cols="12" md="3" 
        v-for="item in list"
        :key="item.id"
      >
        <v-card
          :to="'/pakiet-kategoria/'+item.id"
        >
          <v-card-text style="font-weight: 700;color: #222">
            <v-avatar
              :color="item.color"
              size="28"
            >{{item.name[0]}}</v-avatar>
            <span style="padding-left: 12px">{{item.name}}</span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    breadcrumb: [
      {
        text: 'Pulpit',
        disabled: false,
        to: '/panel',
      },
      {
        text: 'Lista Klientów - pakiety',
        disabled: false,
        to: '/pakiety',
      },
      {
        text: 'Lista pakietów',
        disabled: true,
        to: '/pakiety-kategorie',
      },
    ],

    list: [
      {
        name: 'Nazwa Klienta',
        color: '#777',
      },
    ],
  }),
  methods: {

  },
  mounted(){
    this.$store.commit('loader');
    this.$axios({url: this.$store.state.api +'/category_package', data: {}, method: 'GET' })
      .then(resp => {
        this.list = resp.data;
        console.log(resp.data);
      })
      .catch(err => {
        this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
      .then (() => {
        this.$store.commit('loader_off');
      })
  }
};
</script>

<style lang="scss" scoped>
.classic_table {
  margin-top: 25px;
}
.paid {
  background-color: green;
  color: white;
  padding: 5px 10px;
  text-align: center;
}
.not_paid {
  background-color: red;
  color: white;
  padding: 5px;
  text-align: center;
}
</style>